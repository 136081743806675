<template>
  <k-field-group language-prefix="home.storeDetails.fields">
    <k-form-dialog v-bind="$attrs" v-on="$listeners" :panels="panels">
      <template #panel.0>
        <v-row class="fill-height">
          <v-col cols="6">
            <form-sheet class="fill-height">
              <KTextField v-model="values.name" field="name" required/>
              <KTextField v-model="values.phoneNumber" field="phoneNumber"/>
              <KTextField v-model="values.email" field="email" type="email" required/>
            </form-sheet>
          </v-col>
        </v-row>
      </template>
      <template #panel.1>
        <v-row class="fill-height">
          <v-col cols="6">
            <k-subtitle>{{ $t('home.storeDetails.fields.showroomLocation') }}</k-subtitle>
            <form-sheet class="fill-height">
              <KTextField v-model="values.showroomAddress.addressLine1" :label="$t('address.addressLine1')" required/>
              <KTextField v-model="values.showroomAddress.addressLine2" :label="$t('address.addressLine2')"/>
              <KTextField v-model="values.showroomAddress.zipCode" :label="$t('address.zipCode')" required/>
              <KTextField v-model="values.showroomAddress.city" :label="$t('address.city')" required/>
              <CountryAutocomplete :return-object="false"
                                   v-model="values.showroomAddress.countryId"
                                   :label="$t('address.countryId')"
                                   required/>

            </form-sheet>
          </v-col>
          <v-col cols="6">
            <k-subtitle>{{ $t('home.storeDetails.fields.shippingAddress') }}</k-subtitle>
            <form-sheet class="fill-height">
              <v-row justify="end" class="mt-2 mb-2 mr-1">
                <v-btn small text tile @click="values.shippingAddress.mapResponse({...values.shippingAddress})">
                  {{ $t('home.actions.copyFromShipping') }}
                </v-btn>
              </v-row>
              <KTextField v-model="values.shippingAddress.addressLine1" :label="$t('address.addressLine1')" required/>
              <KTextField v-model="values.shippingAddress.addressLine2" :label="$t('address.addressLine2')"/>
              <KTextField v-model="values.shippingAddress.zipCode" :label="$t('address.zipCode')" required/>
              <KTextField v-model="values.shippingAddress.city" :label="$t('address.city')" required/>
              <CountryAutocomplete :return-object="false"
                                   v-model="values.shippingAddress.countryId"
                                   :label="$t('address.countryId')"
                                   required/>
            </form-sheet>
          </v-col>
        </v-row>
      </template>
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import CountryAutocomplete from '@/modules/country/components/CountryAutocomplete.vue';
import FormSheet from '@/components/FormSheet.vue';
import KSubtitle from '@/components/layout/KSubtitle.vue';

export default {
  name: 'UpdateStoreDetails',
  components: {
    KSubtitle,
    KFormDialog,
    CountryAutocomplete,
    KFieldGroup,
    KTextField,
    FormSheet,
  },
  props: {
    values: {
      type: Object,
    },
  },
  computed: {
    panels() {
      return [
        { name: this.$t('global.defaultTabName') },
        { name: this.$t('home.storeDetails.tabs.address') },
      ];
    },
  },
};
</script>
