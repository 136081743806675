<template>
  <v-sheet class="pa-4">
    <v-container>
      <v-row no-gutters>
        <k-subtitle :title-class="'d-flex flex-grow-1'">
          <span>{{ $t('home.storeDetails.title') }}</span>
          <VSpacer/>
          <v-btn v-if="isAdmin|isOrderer"
                 color="text--secondary"
                 icon
                 @click.stop="editStoreDetailsClick">
            <v-icon>$edit</v-icon>
          </v-btn>
        </k-subtitle>
      </v-row>
      <v-row>
        <v-col>
          <div v-if="!storeDetailsLoaded" style="height:150px;">
            <VSkeletonLoader type="image" width="100%" height="100%"/>
          </div>
          <iframe v-if="storeDetailsLoaded && mapsUrl"
                  :src="mapsUrl"
                  allowfullscreen
                  height="100%"
                  style="border:0" width="100%">
          </iframe>
        </v-col>
      </v-row>
      <!-- todo: proper fix for word breaks in k-text-display -->
      <v-row style="word-break: break-all">
        <v-col cols="6">
          <k-display-group v-if="storeDetailsLoaded"
                           language-prefix="home.storeDetails.fields">
            <k-text-display class="mb-4" field="name">
              <strong>{{ storeDetails.name }}</strong>
            </k-text-display>
            <k-text-display class="mb-4" field="phoneNumber">{{ storeDetails.phoneNumber }}</k-text-display>
            <k-text-display class="mb-4" field="email">{{ storeDetails.email }}</k-text-display>
            <k-text-display class="mb-4" field="dealerCode">{{ storeDetails.branchExtension !== '' ? storeDetails.dealerCode + "-" + storeDetails.branchExtension : storeDetails.dealerCode }}</k-text-display>
          </k-display-group>
          <div v-else>
            <VSkeletonLoader v-for="n in 4" :key="n" class="mb-4" type="text"/>
          </div>
        </v-col>
        <v-col cols="6">
          <k-display-group language-prefix="home.storeDetails.fields">
            <k-text-display field="showroomLocation" class="mb-4">
              <KAddressDisplay :loading="!storeDetailsLoaded"
                               :address="storeDetails.showroomAddress"/>
            </k-text-display>
            <k-text-display field="shippingAddress" class="mb-4">
              <KAddressDisplay :loading="!storeDetailsLoaded"
                               :address="storeDetails.shippingAddress"/>
            </k-text-display>
          </k-display-group>
        </v-col>
      </v-row>
    </v-container>
    <UpdateStoreDetails v-model="updateDialog"
                        :request="updateRequest"
                        :title="$t('home.storeDetails.updateTitle')"
                        :values="updateFormValues"
                        @change="getStoreDetails"/>
  </v-sheet>
</template>

<script>
import KSubtitle from '@/components/layout/KSubtitle.vue';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import KAddressDisplay from '@/components/crud/display/KAddressDisplay.vue';

import {StoreDetails} from '@/application/models/StoreDetails.js';
import {showAddress, updateAddress} from '@/modules/home/api/index.js';
import {mapGetters} from 'vuex';
import UpdateStoreDetails from '@/modules/home/components/UpdateStoreDetails.vue';

export default {
  name: 'StoreDetails',
  components: {
    UpdateStoreDetails,
    KSubtitle,
    KDisplayGroup,
    KTextDisplay,
    KAddressDisplay,
  },
  data() {
    return {
      storeDetailsLoaded: false,
      storeDetails: new StoreDetails(),
      updateDialog: false,
      updateFormValues: null,
    };
  },
  computed: {
    ...mapGetters('settings', {currentStoreId: 'getCurrentStoreId'}),
    ...mapGetters('authorisation', {isAdmin: 'isAdmin', isApprover: 'isApprover', isOrderer: 'isOrderer'}),
    mapsUrl() {
      const address = this.storeDetails?.shippingAddress;
      if (!address) return;
      const addressUri = encodeURI(`${address.addressLine1} ${address.zipCode} ${address.city}`);
      return `https://www.google.com/maps/embed/v1/search?q=${addressUri}&key=${process.env.VUE_APP_API_KEY_GOOGLE_MAPS}`;
    },
  },
  watch: {
    currentStoreId() {
      this.getStoreDetails();
    },
  },
  methods: {
    async getStoreDetails() {
      this.storeDetailsLoaded = false;
      this.storeDetails = new StoreDetails(); // reset;
      const {data: {data}} = await showAddress(this.currentStoreId);
      this.storeDetails.mapResponse(data);
      this.storeDetailsLoaded = true;
    },
    async editStoreDetailsClick() {
      this.updateFormValues = new StoreDetails();
      const {data: {data}} = await showAddress(this.currentStoreId);
      this.updateFormValues.mapResponse(data);
      this.updateDialog = true;
    },
    updateRequest() {
      return updateAddress(this.currentStoreId, this.updateFormValues);
    },
  },
  created() {
    this.getStoreDetails();
  },
};
</script>
