export default {
    NEW: 1,
    PENDING_APPROVAL: 2,
    APPROVED: 3,
    IN_PRODUCTION: 4,
    SHIPPED: 5,
    CANCELED: 6,
    REJECTED: 7,
    ON_HOLD: 8,
    OFFER_REQUESTED: 9,
    WAITING_FOR_CHECKOUT: 10
}
