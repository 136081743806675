<template>
  <v-card tile elevation="0">
    <v-card-title>
      <h3 class="text-h3 text-uppercase">{{ $t('orders.myOrders.pageTitle') }}</h3>
    </v-card-title>
    <k-crud-table ref="table"
                  :headers="crudHeaders"
                  :rowsPerPage="5"
                  :index-request="indexRequest"
                  language-prefix="orders.crudHeaders"
                  resource="orders">
      <template #item.statusIcon="{item: {orderStatusId}}">
        <v-row class="justify-center align-center">
          <v-col cols="auto" style="height: 48px">
            <OrderStatusIcon :orderStatusId="orderStatusId"/>
          </v-col>
        </v-row>
      </template>
      <template #item.orderStatusId="{item: {orderStatusId}}">
        <span>{{ $t(`orders.orderStatus.${getOrderStatus(orderStatusId)}`) }}</span>
      </template>
      <template #item.date="{item: {date}}">
        <span>{{ dayjs(date, 'YYYY-MM-DD HH:mm:ss').format('LL') }}</span>
      </template>
      <template #actions="{item}">
        <v-btn icon :to="{name: 'my-orders.show', params: {orderId: item.id}}">
          <v-icon>$visible</v-icon>
        </v-btn>
      </template>
    </k-crud-table>
  </v-card>
</template>

<script lang="js">
import orderStatus from '@/application/enums/orderStatus.js';
import KCrudTable from '@/components/crud/KCrudTable.vue';
import OrderStatusIcon from '@/modules/orders/components/OrderStatusIcon.js';
import dayjs from '@/plugins/dayjs.js';
import { storeIndex } from '@/modules/orders/api/order.js';
import { mapGetters } from 'vuex';

export default {
  name: 'MyOrders',
  components: {
    OrderStatusIcon,
    KCrudTable,
  },
  watch: {
    async storeId() {
      this.$refs.table.reload();
    },
  },
  computed: {
    ...mapGetters('settings', { storeId: 'getCurrentStoreId' }),
    crudHeaders() {
      return [
        {
          value: 'statusIcon',
          text: '',
          width: 48,
        },
        {
          value: 'orderStatusId',
          language: 'orderStatusId',
        },
        {
          value: 'storeName',
          language: 'storeName',
        },
        {
          value: 'userName',
          language: 'userName',
        },
        {
          value: 'countryName',
          language: 'countryName',
        },
        {
          value: 'date',
          language: 'date',
        },
      ];
    },
  },
  mounted() {
    this.$refs.table.pagination.perPage = 5;
  },
  methods: {
    indexRequest(...args) {
      return storeIndex(this.storeId, ...args);
    },
    dayjs,
    getOrderStatus(id) {
      return Object.keys(orderStatus).find(key => orderStatus[key] === id);
    },
  },
};
</script>
