import { Address } from '@/application/models/Address.js';
import Model from './model';

export class StoreDetails extends Model {
  id = 0;
  name = '';
  email = '';
  dealerCode = 0;
  dealerLevel = '';
  branchExtension = '';
  phoneNumber = '';
  mobilePhoneNumber = '';
  showroomAddress = new Address();
  shippingAddress = new Address();
  billingAddress = new Address();

  mapForRequest() {
    return {
      id:this.id,
      name: this.name,
      email: this.email,
      dealerCode: this.dealerCode,
      dealerLevel: this.dealerLevel,
      branchExtension: this.branchExtension,
      phoneNumber: this.phoneNumber,
      mobilePhoneNumber: this.mobilePhoneNumber,
      showroomAddress: this.showroomAddress.mapForRequest(),
      shippingAddress: this.shippingAddress.mapForRequest(),
      billingAddress: this.billingAddress.mapForRequest(),
    };
  }

  mapResponse(data) {
    return super.mapResponse(data);
  }
}
