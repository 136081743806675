<template>
  <page-layout>
    <template #header>
      <div class="mb-8">
        <k-title>{{ $t('home.pageTitle') }}</k-title>
      </div>
    </template>
    <template #flow-actions>
      <v-btn :color="isFavourite ? 'primary' : 'secondary'" :depressed="isFavourite" :outlined="!isFavourite" tile @click="toggleFavourite" :loading="isLoading">
        <v-icon left>$favourite</v-icon>
        {{ $t('home.actions.favourite') }}
      </v-btn>
    </template>
    <v-row>
      <v-col sm="12" md="7" lg="8">
        <MyOrders/>
      </v-col>
      <v-col sm="12" md="5" lg="4">
        <StoreDetails v-if="isAdmin|isOrderer"/>
      </v-col>
    </v-row>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/layout/PageLayout.vue';
import KTitle from '@/components/layout/KTitle.vue';
import StoreDetails from '@/modules/home/components/StoreDetails.vue';
import { mapActions, mapGetters } from 'vuex';
import MyOrders from '@/modules/home/components/MyOrders.vue';
import { toggleFavourite } from '@/modules/home/api';

export default {
  name: 'Home',
  components: {
    MyOrders,
    KTitle,
    PageLayout,
    StoreDetails
  },
  data(){
    return{
      isLoading: false
    }
  },
  computed: {
    ...mapGetters('settings', {currentStoreId: 'getCurrentStoreId'}),
    ...mapGetters('authorisation', ['isAdmin', 'isApprover', 'isOrderer', 'getFavouriteStores']),
    isFavourite(){
      return this.getFavouriteStores.filter(store => store.id === this.currentStoreId).length > 0;
    }
  },
  methods: {
    ...mapActions('authorisation', ['fetchProfile']),
    async toggleFavourite(){
      this.isLoading = true;
      await toggleFavourite(this.currentStoreId);
      await this.fetchProfile();
      this.isLoading = false;
    }
  }
};
</script>
