import { get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const storeIndex = (storeId, page, perPage, search, sortBy, descending, params) =>
  getPaginated(`store/${storeId}/order`, page, perPage, search, sortBy, descending, params);

export const clientIndex = (clientId, page, perPage, search, sortBy, descending, params) =>
  getPaginated(`client/${clientId}/order`, page, perPage, search, sortBy, descending, params);

export const autocomplete = (storeId, page, perPage, search, sortBy, descending, params) =>
  getPaginated(`store/${storeId}/order`, page, perPage, search, sortBy, descending, params);

export const show = (id) => get(`order/${id}`);

export const reorder = (id) => post(`order/${id}/reorder`);

export const cancel = (id) => post(`order/${id}/cancel`);

export const download = (id) => get(`order/${id}/download`, { responseType: 'blob' });

export const exportOrders = (params) => post(`order/export`, params, { responseType: 'blob' });

export const approve = (orderId, isApproved, comment = undefined) =>
  post(`order/${orderId}/approval`, {
    approved: isApproved,
    comment,
  });

export const setToApproved = (orderId) => post(`order/${orderId}/approve`);

export const setToOnHold = (orderId) => post(`order/${orderId}/on-hold`);

