<template>
  <div class="flex-grow-1">
    <VSkeletonLoader v-if="loading" type="paragraph"/>
    <div v-else class="display-address">
      <div>{{ address.addressLine1 }}</div>
      <div v-if="address.addressLine2">{{ address.addressLine2 }}</div>
      <div>{{ address.zipCode }} {{ address.city }}</div>
      <div v-if="address.countryId">{{ $t(`countries.${address.countryId}`) }}</div>
    </div>
  </div>
</template>

<script>
// @TODO country enum?

export default {
  name: 'KAddressDisplay',
  props: {
    address: {
      required: true,
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // country() {
    //   return Object.keys(country).map((name) => {
    //     return {
    //       name: this.$t(`country.${name}`),
    //       id: country[name],
    //     };
    //   });
    // },
  },
};
</script>
