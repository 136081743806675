import { get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const autocomplete = (...args) =>
  getPaginated(`store/autocomplete`, ...args);

export const showAddress = (id) => get(`store/${id}/address`);

export const updateAddress = (id, values) => put(`store/${id}/address`, values);

export const toggleFavourite = (storeId) => post(`profile/store/${storeId}/favourite`);
