<template>
  <KPaginatedAutocomplete v-bind="$attrs" v-on="$listeners" :paginator="autocomplete"/>
</template>

<script>
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.vue';
import { autocomplete } from '@/modules/country/api';
import { mapGetters } from 'vuex';

export default {
  name: 'CountryAutocomplete',
  components: { KPaginatedAutocomplete },
  methods: {
    async autocomplete(...args) {
      const response = await autocomplete(this.currentStoreId, ...args);
      return response;
    }
  },
  computed: {
    ...mapGetters('settings', { currentStoreId: 'getCurrentStoreId' }),
  }
};
</script>
